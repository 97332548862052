<template>
  <b-container class="checkout-payment-container">
    <payment-method-options
      :total="cartItemsTotal()"
      :payment-method-errors="paymentMethodErrors"
    />
  </b-container>
</template>

<script>
import PaymentMethodOptions from '@/components/Payments/PaymentMethodOptions'
import { mapGetters } from 'vuex'

export default {
  name: "CheckoutPayment",
  components: { PaymentMethodOptions },
  props: {
    paymentMethodErrors: {
      type: String,
      default: null,
    },
  },
  methods: {
    ...mapGetters('checkout', [
      'cartItemsTotal',
      ]),
  },
  async mounted() {
    this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })
  },
}
</script>
<style scoped lang="scss">
  .checkout-payment-container {
    height: 100%;
  }
</style>
